import React from 'react'
//CSS
import styles from './TopNav.module.css';
import NotificationsIcon from '../Notifications/NotificationsIcon';
import { useAuth0 } from '@auth0/auth0-react';

const TopNav = () => {

  const { logout } = useAuth0();

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <img src='/img/build-your-body-logo.svg' />
      </div>
      <div className={styles.right}>
        <img src='/img/logout.svg' onClick={() => logout()} />
        <NotificationsIcon />
      </div>
    </div>
  )
}

export default TopNav