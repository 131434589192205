import React from 'react'
//CSS
import routeStyles from './Routes.module.css'

const Exercises = () => {
    return (
        <div className={routeStyles.container}>
            <h2>Exercise Library</h2>
            {/*exercises && exercises.length > 0 ? (
                exercises?.map((item) => (
                    <div key={item.id} style={{ color: item.is_custom ? 'red' : '' }}>
                        {item.title}
                    </div>
                ))
            ) : (
                <p>No exercises.</p>
            )*/}
        </div>
    )
}

export default Exercises