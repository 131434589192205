import React, { useState } from 'react'
//CSS
import styles from './Drawer.module.css';
import DrawerItem from './DrawerItem/DrawerItem';
//Data
import { NavbarData } from '../../Data/NavbarData';
import { NavbarItem } from '../../Types/INavbarItem';

const Drawer = () => {

  const [drawerItemStatus, setDrawerItemStatus] = useState([
    ...NavbarData
  ]);

  
  const addActiveStatus = (id:number) => {

    // Destructure current state array
    const state: NavbarItem[] = [ ...drawerItemStatus ];
    state.forEach(element => {
      element.active = false;
    });

    // Find object from id
    let index = state.findIndex(x => x.id === id)

    // Set clicked link to active
    state[index].active = true;

    // Set state with updated active status
    setDrawerItemStatus(() => {
      return [
        ...state
      ]
    });

}

  const removeActiveStatus = () :void => {
    const state: NavbarItem[] = [ ...drawerItemStatus ];
    state.forEach(element => {
      element.active = false;
    });
}

removeActiveStatus();

  return (
    <div className={styles.container}>
      {NavbarData.map((item) => {
        return <DrawerItem img={item.icon} item={item} url={item.url} statusHandler={addActiveStatus} />
      })}
    </div>
  )
}

export default Drawer