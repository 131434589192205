import React from 'react'
//CSS
import styles from './LoadingIcon.module.css';

const LoadingIcon = () => {
  return (
    <div className={styles.container}>
        <div className={`${styles.circle} ${styles.circle1}`}></div>
        <div className={`${styles.circle} ${styles.circle2}`}></div>
        <div className={`${styles.circle} ${styles.circle3}`}></div>
    </div>
  )
}

export default LoadingIcon