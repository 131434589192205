import React, { useEffect, useState } from 'react'
import SearchBar from '../../../Utils/SearchBar/SearchBar';
import axios from 'axios';
import { Routine } from '../../../../Types/IRoutineTypes';
import Card from '../../../Utils/Card/Card';
import styles from './Routines3xpw.module.css';
import LoadingScreen from '../../../Utils/LoadingScreen/LoadingScreen';
import { useAuth0 } from '@auth0/auth0-react';

const Routines3xpw = () => {

    const [routines, setRoutines] = useState<Routine[]>([]);
    const [isLoading, setIsLoading] = useState<Boolean>(true);
    const { getAccessTokenSilently } = useAuth0();

    const fetchWorkouts = async () => {

        try {

            const accessToken = await getAccessTokenSilently();
            console.log(accessToken);

            let response = await axios.get<Routine[]>('https://api.buildyourbody.liamimray.co.uk/api/routines', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });

            let data = response.data.filter((x) => {
                return x.title.toLowerCase().includes("3xp");
            })

            setRoutines(data);
            setIsLoading(false);

        } catch (error) {

            console.error(error);

        }
    };

    useEffect(() => {
        fetchWorkouts();
    }, [])
    

    if (isLoading) {
        return <LoadingScreen />
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.grid}>
                    <SearchBar data={routines} />
                    <div></div>
                    <div></div>
                    {routines.length > 0 ? (
                        routines.map((item) => (
                            <Card item={item} />
                        ))
                    ) : (
                        <p>No Routines.</p>
                    )}
                </div>
            </div>
        )
    }
}

export default Routines3xpw