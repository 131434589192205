import React from 'react'
//CSS
import styles from './Button.module.css'

type ButtonProps = {
    text: string,
    clickHandler?: () => void
}

const Button = ({text, clickHandler} : ButtonProps) => {
  return (
    <button className={styles.container}
            onClick={clickHandler ? clickHandler : null}>
        {text}
    </button>
  )
}

export default Button