import React, { useEffect, useState } from 'react'
import { Routine } from '../../../../Types/IRoutineTypes';
import axios from 'axios';
import SearchBar from '../../../Utils/SearchBar/SearchBar';
//CSS
import styles from './Routines4xpw.module.css';
import Card from '../../../Utils/Card/Card';
import LoadingScreen from '../../../Utils/LoadingScreen/LoadingScreen';
import { useAuth0 } from '@auth0/auth0-react';

const Routines4xpw = () => {

    const [routines, setRoutines] = useState<Routine[]>([]);
    const [isLoading, setIsLoading] = useState<Boolean>(true);
    const { getAccessTokenSilently } = useAuth0();
    

    const fetchWorkouts = async () => {

        try {

            const accessToken = await getAccessTokenSilently();

            let response = await axios.get<Routine[]>('https://api.buildyourbody.liamimray.co.uk/api/routines', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });

            let data = response.data.filter((x) => {
                return x.title.toLowerCase().includes("4xp");
            })

            setRoutines(data);
            setIsLoading(false);

        } catch (error) {

            console.error(error);

        }
    };


    useEffect(() => {
        fetchWorkouts();
    }, [])

    if (isLoading) {
        return <LoadingScreen />
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.grid}>
                    <SearchBar data={routines} />
                    <div></div>
                    <div></div>
                    {routines.length > 0 ? (
                        routines.map((item) => (
                            <Card item={item} />
                        ))
                    ) : (
                        <p>No Routines.</p>
                    )}
                </div>
            </div>
        )
    }
}

export default Routines4xpw