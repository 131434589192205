import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Auth0Provider
      domain="dev-cf4btky4qttcaxz4.us.auth0.com"
      clientId="OO3R0bwLeyrNfj2NMqnt8Tnel4vWVgdd"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: `https://dev-cf4btky4qttcaxz4.us.auth0.com/api/v2/`,
        scope: "read:current_user",
      }}
    >
      <App />
    </Auth0Provider>
  </BrowserRouter>
);
