import { NavbarItem } from "../Types/INavbarItem";

export const NavbarData: NavbarItem[] = [
    {
        id: 1,
        title: "Library",
        icon: "/img/library.svg",
        active: true,
        url: "/",
        class: ""
    },
    {
        id: 2,
        title: "Routines",
        icon: "/img/workouts.svg",
        active: false,
        url: "/routines",
        class: ""
    },
    {
        id: 3,
        title: "Nutrition",
        icon: "/img/nutrition.svg",
        active: true,
        url: "/nutrition",
        class: ""
    }
]