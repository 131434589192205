import React, { useState } from 'react';
import styles from './Calculator.module.css'; // Importing CSS for styling
import Button from '../Button/Button';

interface FormData {
  age: number;
  weight: number; // in kg
  height: number; // in cm
  gender: 'male' | 'female';
  activityLevel: string;
}

const activityFactors: { [key: string]: number } = {
  sedentary: 1.2,
  lightlyActive: 1.375,
  moderatelyActive: 1.55,
  veryActive: 1.725,
  superActive: 1.9,
};

const CalorieCalculator: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    age: 25,
    weight: 70,
    height: 175,
    gender: 'male',
    activityLevel: 'sedentary',
  });

  const [calories, setCalories] = useState<number | null>(null);
  const [bmr, setBmr] = useState<number | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'age' || name === 'weight' || name === 'height' ? Number(value) : value,
    });
  };

  const calculateCalories = () => {
    const { age, weight, height, gender, activityLevel } = formData;

    // Mifflin-St Jeor Equation for BMR (RMR)
    let bmrValue: number;
    if (gender === 'male') {
      bmrValue = 10 * weight + 6.25 * height - 5 * age + 5;
    } else {
      bmrValue = 10 * weight + 6.25 * height - 5 * age - 161;
    }

    // Calculate Total Daily Energy Expenditure (TDEE)
    const tdee = bmrValue * activityFactors[activityLevel];

    setCalories(tdee);
    setBmr(bmrValue);
  };

  // Suggested caloric intake ranges for weight loss and gain
  const calculateDeficitCalories = (tdee: number, percentage: number) => tdee * (1 - percentage);
  const calculateSurplusCalories = (tdee: number, percentage: number) => tdee * (1 + percentage);

  return (
    <div className={styles.calculator_container}>
      <h1 className={styles.calculator_title}>Calorie Calculator</h1>
      <div className={styles.form_container}>
        <div className={styles.form_group}>
          <label>
            Age:
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className={styles.form_group}>
          <label>
            Weight (kg):
            <input
              type="number"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className={styles.form_group}>
          <label>
            Height (cm):
            <input
              type="number"
              name="height"
              value={formData.height}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className={styles.form_group}>
          <label>
            Gender:
            <select name="gender" value={formData.gender} onChange={handleChange}>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </label>
        </div>
        <div className={styles.form_group}>
          <label>
            Activity Level:
            <select name="activityLevel" value={formData.activityLevel} onChange={handleChange}>
              <option value="sedentary">Sedentary (little to no exercise)</option>
              <option value="lightlyActive">Lightly Active (1-3 days/week)</option>
              <option value="moderatelyActive">Moderately Active (3-5 days/week)</option>
              <option value="veryActive">Very Active (6-7 days/week)</option>
              <option value="superActive">Super Active (physical job, hard exercise)</option>
            </select>
          </label>
        </div>
      </div>
      <Button text='Calculate' clickHandler={calculateCalories} />

      {calories && bmr && (
        <div className={styles.results_container}>
          <h2>Results:</h2>
          <div className={styles.results_group}>
            <h3>BMR (Basal Metabolic Rate): {bmr.toFixed(2)} calories/day</h3>
            <h3>Total Daily Energy Expenditure (TDEE): {calories.toFixed(2)} calories/day</h3>
          </div>
          <div className={styles.caloric_intake_group}>
            <h4>Suggested Caloric Intake for Weight Loss:</h4>
            <ul>
              <li>10% deficit (Mild) : <strong>{calculateDeficitCalories(calories, 0.10).toFixed(2)}</strong> calories/day</li>
              <li>20% deficit (Moderate): <strong>{calculateDeficitCalories(calories, 0.20).toFixed(2)}</strong> calories/day</li>
              <li>30% deficit (Extreme): <strong>{calculateDeficitCalories(calories, 0.30).toFixed(2)}</strong> calories/day</li>
            </ul>
          </div>
          <div className={styles.caloric_intake_group}>
            <h4>Suggested Caloric Intake for Weight Gain:</h4>
            <ul>
              <li>10% surplus (Mild): <strong>{calculateSurplusCalories(calories, 0.10).toFixed(2)}</strong> calories/day</li>
              <li>20% surplus (Moderate): <strong>{calculateSurplusCalories(calories, 0.20).toFixed(2)}</strong> calories/day</li>
              <li>30% surplus (Extreme): <strong>{calculateSurplusCalories(calories, 0.30).toFixed(2)}</strong> calories/day</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalorieCalculator;
