import { useNavigate, Link } from 'react-router-dom';
//CSS
import styles from './ResultItem.module.css'
import { Exercise, Routine } from '../../../../Types/IRoutineTypes';
import { Resource } from '../../../../Types/IResource';

type ResultItemProps = {
    item: Resource
}

const ResultItem = ({item} : ResultItemProps) => {

const navigate = useNavigate();

  return (
    <Link to={`/${item.title.toLowerCase()}`}>
        <div className={styles.container}>
            <div className={styles.left}>
                {/*
                    <div className={styles.imageContainer}>
                        <img className={styles.image} src={item} />
                    </div>
                */
                }
                <p className={styles.title}>{item.title}</p>
            </div>
        </div>
    </Link>
  )
}

export default ResultItem