import React from 'react'
//CSS
import styles from './Card.module.css';
//Types
import { Exercise, Routine } from '../../../Types/IRoutineTypes';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';

type CardProps = {
    item: Routine | Exercise
}

const Card = ({ item }: CardProps) => {

    // Type guard to check if the item is a Routine
    const isRoutine = (item: Routine | Exercise): item is Routine => {
        return (item as Routine).exercises !== undefined;
    }

    const openInHevy = (url: string) => {
        window.location.href = url;
    }

    console.log(item)

    return (
        <div className={styles.gridItem} key={item.id}>
            <div className={styles.gridItem__left}>
                <img src='/img/routine-image.png' />
            </div>
            <div className={styles.gridItem__right}>
                <div className={styles.gridItem__right__top}>
                    <h3>{item.title}</h3>
                </div>
                <div className={styles.gridItem__right__bottom}>
                    <Button clickHandler={item.hevyAppUrl ? () => openInHevy(`http://hevy.com/routine/${item.hevyAppUrl}`) : undefined} text='Open in Hevy' />
                </div>
            </div>
        </div>
    );
}

export default Card