import React, { ReactNode } from 'react'
//CSS
import styles from './Main.module.css';
//Components
import TopNav from '../TopNav/TopNav';

type MainProps = {
  children: ReactNode;
}

const Main = ({ children }: MainProps) => {
  return (
    <div className={styles.container}>
      <TopNav />
      <div className={styles.body}>
        {children}
      </div>
    </div>
  )
}

export default Main