import React from 'react'
//CSS
import styles from './DrawerItem.module.css';
import { NavbarItem } from '../../../Types/INavbarItem';
import { Link, useNavigate } from 'react-router-dom';

type DrawerItemProps = {
    img: string,
    item: NavbarItem,
    url: string,
    statusHandler: (id: number) => void
}

const DrawerItem = ({ img, item, statusHandler }: DrawerItemProps) => {

    const navigate = useNavigate();

    const currentPath: string = window.location.pathname;

    const isCurrentPath = (title: string): boolean => {
        if (title.toLowerCase() === "library") {
            return currentPath === "/";
        } else {
            return currentPath.startsWith(`/${title.toLowerCase()}`);
        }
    }

    return (
        <div
            onClick={!isCurrentPath(item.title) ? () => { statusHandler(item.id); navigate(item.url) } : null}
            className={styles.container}
            style={{
                backgroundColor:
                    isCurrentPath(item.title) ? '#EBEBEB' : ''
            }}
        >
            <img src={img} />
        </div>
    )
}

export default DrawerItem