import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingScreen from './Components/Utils/LoadingScreen/LoadingScreen';


const AuthLogin = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading || !isAuthenticated) {
    return <LoadingScreen />
  } else {
    return null
  }

};

export default AuthLogin;
