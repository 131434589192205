import React from 'react'
import Calculator from '../Utils/Calculator/Calculator'
//CSS
import routeStyles from './Routes.module.css';
import CalorieCalculator from '../Utils/Calculator/Calculator';

const Nutrition = () => {
    return (
        <div className={routeStyles.container}>
            <CalorieCalculator />
        </div>
    )
}

export default Nutrition