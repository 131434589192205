import React, { useEffect, useState } from 'react'
import { Routine } from '../../../Types/IRoutineTypes';
import axios from 'axios';
//CSS
import routeStyles from '../Routes.module.css';
import styles from './Routines.module.css';
import Exercises from '../Exercises';
import Card from '../../Utils/Card/Card';
import SearchBar from '../../Utils/SearchBar/SearchBar';
import TabbedView from '../../Utils/TabbedView/TabbedView';
import { ITab } from '../../../Types/ITab';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Routines3xpw from './3xpw/Routines3xpw';
import Routines4xpw from './4xpw/Routines4xpw';

const Routines = () => {

  const navigate = useNavigate();

  

  const tabs: ITab[] = [
    {
      title: "3XPW",
      active: true
    },
    {
      title: "4XPW",
      active: false
    }
  ]

  useEffect(() => {
    navigate('/routines/3xpw')
  }, [])

    return (
      <div className={routeStyles.container}>
        <TabbedView data={tabs} />
        <Routes>
          <Route path="/3xpw" element={<Routines3xpw />}/>
          <Route path="/4xpw" element={<Routines4xpw/>}/>
        </Routes>
      </div>
    )
}

export default Routines