import { useAuth0 } from '@auth0/auth0-react';
import './App.css';
//Components
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Exercise, ExerciseCollection, Routine } from './Types/IRoutineTypes';
import Drawer from './Components/Drawer/Drawer';
import Main from './Components/Main/Main';
import Routines from './Components/Routes/Routines/Routines';
import Exercises from './Components/Routes/Exercises';
import { Route, Routes } from 'react-router-dom';
import AuthLogin from './AuthLogin';
import Nutrition from './Components/Routes/Nutrition';

function App() {

  const { isAuthenticated, user, logout } = useAuth0();


  const [exercises, setExercises] = useState<Exercise[]>([]);

  useEffect(() => {
    if (isAuthenticated) {

      const fetchExercises = async () => {
        try {
          let response = await axios.get('https://api.buildyourbody.liamimray.co.uk/api/exercises');
          console.log(response.data)
          setExercises(response.data);
          console.log(exercises);

        } catch (error) {
          console.error(error);
        }
      };

      fetchExercises();

    }
  }, [isAuthenticated]); // Dependency array with isAuthenticated

  useEffect(() => {
    console.log(exercises.length)
  }, [
    exercises
  ])

  return (
    <>
      {isAuthenticated ? (
        <div className='wrapper'>
          <Drawer />
          <Main>
            <Routes>
              <Route path="/" element={<Exercises />} />
              <Route path="/routines" element={<Routines />}>
                <Route path='/routines/3xpw' />
                <Route path='/routines/4xpw' />
              </Route>
              <Route path="/nutrition" element={<Nutrition />} />
            </Routes>
          </Main >
        </div>
      ) : (
        <AuthLogin />
      )
      }
    </>
  );
}

export default App;
